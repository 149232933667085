import React, { useState } from "react"
import { graphql } from "gatsby"
import { useFormik } from "formik"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay"
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons/faArrowRightLong"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import TheaterVideo from "../components/Theater/TheaterVideo"
import Layout from "components/layout"
import SEO from "components/seo"
import NuvoImage from "../components/NuvoImage"
import { Section, Container } from "../components/Layouts"
import { Spinner } from "../components/Loading"

// Formiq input fields rules and error messages
const validate = values => {
  const errors = {}

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address"
  }

  return errors
}

const SingleMarketingPage = ({ data, path, location }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const post = data.allUniquePagesJson.nodes[0]
  const { href } = location

  const {
    hero,
    customWebsites,
    strategiesSection,
    cardsSection,
    ideasSection,
    marketingSection
  } = post

  // onSubmit post form data
  // redirect to thank-you page if succesfull
  const handleSubmit = async (fields, cb) => {
    try {
      setLoading(true)
      const res = await axios.post(
        `https://getform.io/f/${process.env.GATSBY_GETFORM_BLOG_ID}`,
        fields
      )
      cb()
      setLoading(false)
      setError("")
    } catch (err) {
      setLoading(false)
      setError("Oops! Something went wrong.")
    }
  }

  // formik hook handles onchange, setting values, onSubmit ....
  const formik = useFormik({
    initialValues: {
      from: href,
      name: "",
      email: "",
      companyName: "",
      message: "",
      phoneNumber: ""
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, () => resetForm())
    }
  })

  const { touched, errors } = formik
  return (
    <Layout path={path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />

      <div className="sm">
        <Section>
          <Container Xlarge>
            <div className="sm__hero">
              <div>
                <h1>{hero.heading}</h1>
                <p>LEARN MORE & WATCH OUR VIDEO</p>
                <TheaterVideo
                  videoUrl="https://wuwta.wistia.com/medias/jiw50e99p1"
                  language="en"
                  asWrapper
                  buttonClass="contained">
                  <div className="sm__play">
                    <FontAwesomeIcon icon={faPlay} />
                  </div>
                </TheaterVideo>
              </div>
              <div>
                <NuvoImage
                  cloudName="nuvolum"
                  publicId={hero.image}
                  responsive
                  width="auto"
                  useAR
                />
              </div>
            </div>
          </Container>
          <Container Xlarge className="sm__subhero ">
            <div className="sm__grid half gap-80">
              <div className="sm__subhero--heading">
                <h2 className="font-weight--700 mb-0">{hero.subheading}</h2>
                <a href="#cta-form" className="sm__btn mt-2 is-hidden-touch">
                  {hero.buttonText}
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </a>
              </div>
              <div>
                <p>{hero.blurb}</p>
                <a href="#cta-form" className="sm__btn mt-2 is-hidden-desktop">
                  {hero.buttonText}
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </a>
              </div>
            </div>
          </Container>

          <Section zeroBottom>
            <Container Xlarge className="sm__custom sm__grid half vcenter">
              <div className="sm__custom--image">
                <NuvoImage publicId={customWebsites.image} />
              </div>
              <div>
                <h3 className="color-black text-lg">
                  {customWebsites.heading}
                </h3>
                <p className="text-md">{customWebsites.content}</p>
                <a href="#cta-form" className="sm__btn outline mt-2">
                  {customWebsites.buttonText}
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </a>
              </div>
            </Container>
          </Section>
        </Section>

        <Section colorBack>
          <Container large>
            <div className="sm__strategies">
              <div className="sm__grid third">
                <div>
                  <h3
                    className="text-lg"
                    dangerouslySetInnerHTML={{
                      __html: strategiesSection.heading
                    }}
                  />
                  <p className="text-md">{strategiesSection.content}</p>
                </div>
              </div>

              <Container medium className="px-0">
                <div className="mt-3 sm__grid half gap-80">
                  {strategiesSection.strategies.map(s => (
                    <div key={s.title}>
                      <p className="bold text-md">{s.title}</p>
                      <p className="text-sm">{s.text}</p>
                    </div>
                  ))}
                </div>

                <a href="#cta-form" className="sm__btn mx-auto blue mt-3">
                  {customWebsites.buttonText}
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </a>
              </Container>
            </div>
          </Container>

          <Section>
            <Container Xlarge className="sm__digital">
              <Container medium className="px-0">
                <h3 className="text-lg has-text-centered-desktop">
                  {cardsSection.heading}
                </h3>
                <p className="text-md has-text-centered-desktop">
                  {cardsSection.content}
                </p>
              </Container>

              <div className="mt-3 sm__grid three gap-80">
                {cardsSection.cards.map(c => (
                  <div key={c.heading}>
                    <p className="bold text-md">{c.heading}</p>
                    <p className="text-sm mt-1">{c.text}</p>
                  </div>
                ))}
              </div>

              <a href="#cta-form" className="sm__btn mx-auto blue mt-3">
                {cardsSection.buttonText}
                <FontAwesomeIcon icon={faArrowRightLong} />
              </a>
            </Container>
          </Section>

          <Section>
            <Container Xlarge>
              <div className="sm__idea sm__grid quarter gap-80">
                <h3 className="text-lg">{ideasSection.heading}</h3>
                <MarkdownViewer
                  className="text-md"
                  markdown={ideasSection.content}
                />
              </div>
            </Container>
          </Section>
        </Section>

        <Section>
          <Container Xlarge>
            <Container medium className="px-0">
              <div className="sm__how">
                <h3 className="text-lg has-text-centered-desktop">
                  {marketingSection.heading}
                </h3>
                <p className="text-md has-text-centered-desktop">
                  {marketingSection.content}
                </p>
              </div>
            </Container>
            <div className="sm__how--flex mt-3">
              {marketingSection.steps.map((step, idx) => (
                <div className={`step-${idx + 1} sm__how--step`} key={step.top}>
                  <p className="color-teal">{`${idx + 1}.`}</p>
                  <p
                    className="mt-0"
                    dangerouslySetInnerHTML={{ __html: step.top }}
                  />
                </div>
              ))}
            </div>
            <a href="#cta-form" className="sm__btn mx-auto blue mt-3">
              {marketingSection.buttonText}
              <FontAwesomeIcon icon={faArrowRightLong} />
            </a>
          </Container>
        </Section>

        <Section>
          <Container Xlarge className="sm__form">
            <div className="sm__grid half gap-80" id="cta-form">
              <div className="sm__form--heading">
                <h3 className="text-lg">
                  Contact Nuvolum <br className="is-hidden-touch" />
                  to Talk to A Specialist
                </h3>
                <p>
                  Nuvolum goes beyond better marketing. We breathe life into
                  your brand and create sustainable, measurable growth. Request
                  a marketing analysis to discover how Nuvolum takes you where
                  you want to go.
                </p>

                <p className="sm__form--bullet text-md mt-3">
                  <FontAwesomeIcon icon={faArrowRightLong} /> Market Analysis
                </p>
                <p className="sm__form--bullet text-md">
                  <FontAwesomeIcon icon={faArrowRightLong} /> Optimized Websites
                </p>
                <p className="sm__form--bullet text-md">
                  <FontAwesomeIcon icon={faArrowRightLong} /> Full-Service
                  Agency
                </p>
              </div>
              <div>
                <form
                  name="Market Analysis"
                  id="cta-form"
                  className={`form`}
                  onSubmit={formik.handleSubmit}>
                  <label className="form__label" htmlFor="name">
                    Name
                  </label>
                  <input
                    className={`form__input ${
                      touched.name && errors.name ? "error" : ""
                    }`}
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />

                  <label className="form__label" htmlFor="phoneNumber">
                    Phone Number
                  </label>
                  <input
                    className={`form__input ${
                      touched.phoneNumber && errors.phoneNumber ? "error" : ""
                    }`}
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    placeholder="xxx-xxx-xxxx"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                  />

                  <label className="form__label" htmlFor="email">
                    Email Address
                  </label>
                  <input
                    className={`form__input ${
                      touched.email && errors.email ? "error" : ""
                    }`}
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />

                  <label className="form__label" htmlFor="companyName">
                    Business Name
                  </label>
                  <input
                    className={`form__input ${
                      touched.companyName && errors.companyName ? "error" : ""
                    }`}
                    id="companyName"
                    name="companyName"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                  />

                  <label className="form__label" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    className={`form__input ${
                      touched.message && errors.message ? "error" : ""
                    }`}
                    id="message"
                    name="message"
                    type="textarea"
                    rows={5}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />

                  <input
                    type="hidden"
                    name="_hpsp"
                    style={{ display: "none !important" }}
                  />

                  {error && <p className="form__error">{error}</p>}

                  <p className="mt-1 mb-2" style={{ fontSize: 12 }}>
                    By providing a telephone number and submitting the form you
                    are consenting to be contacted by SMS text message. Message
                    & data rates may apply. Reply STOP to opt out of further
                    messaging.
                  </p>

                  <div className="submit-form ">
                    <button
                      disabled={loading}
                      className="sm__btn blue"
                      type="submit">
                      Talk to a Specialist
                      <FontAwesomeIcon icon={faArrowRightLong} />
                    </button>
                    {loading && <Spinner />}
                  </div>
                </form>
              </div>
            </div>
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default SingleMarketingPage

export const singleMarketingQuery = graphql`
  query singleMarketingPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          heading
          subheading
          blurb
          videoUrl
          image
          buttonText
        }
        customWebsites {
          heading
          content
          image
          buttonText
        }
        strategiesSection {
          heading
          content
          buttonText
          strategies {
            title
            text
          }
        }
        cardsSection {
          heading
          content
          buttonText
          cards {
            heading
            text
          }
        }
        ideasSection {
          heading
          content
        }
        marketingSection {
          heading
          content
          buttonText
          steps {
            top
          }
        }
      }
    }
  }
`
